import type {
  ExportDataApiRequestParameter,
  ExportDataApiResponse,
} from '../types/apis/exportDataApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * Eula Update Api
 *
 * @param requestParams request parameter
 * @returns
 */
type Metadata = {
  totalRow: number;
  exportUniqueId: string;
};

// type ParsedData = {
//   metadata: Metadata;
//   headers: string[];
//   rows: string[][];
//   footer: string;
// };

const callExportLogDataApi = (
  requestParams: ExportDataApiRequestParameter,
): Promise<ExportDataApiResponse> => {
  // Request parameter
  const jsonParams = {
    /*eslint-disable*/
    corpId: requestParams.corpId,
    from: requestParams.from,
    to: requestParams.to,
    // eslint-enabled
  };
  // (POST)
  /*eslint-disable*/
  const response = callApiUtil
    .get<ExportDataApiResponse>(
      `${API_URL.LOG_DATA_DOWNLOAD_API}?corp_id=${jsonParams.corpId}&from=${jsonParams.from}&to=${jsonParams.to}`,
      // {
      //   headers: { 'Content-Type': 'application/json' },
      // },
      {
        onDownloadProgress: (progressEvent) => {
          const input = progressEvent.target.response;

          const lines = input
            .split('\n')
            .map((line: string) => line.trim())
            .filter(Boolean);

          // Metadata extraction
          const startLine = lines.find((line: string) => line.startsWith('[START]'));

          const totalRowMatch = startLine?.match(/TOTAL_ROW=(\d+)/);
          const exportUniqueIdMatch = startLine?.match(
            /EXPORT_UNIQUE_ID=([\w-]+)/,
          );

          const totalRow = totalRowMatch ? parseInt(totalRowMatch[1], 10) : 0;
          const exportUniqueId = exportUniqueIdMatch
            ? exportUniqueIdMatch[1]
            : '';

          requestParams.setExportUniqueId(exportUniqueId);

          const metadata: Metadata = { totalRow, exportUniqueId };
          requestParams.setTotalRows(totalRow);

          // Parse headers and data
          const headerIndex = lines.findIndex((line: string | string[]) => line.includes('﻿id,'));
          const dataStartIndex = headerIndex + 1;

          const rows: string[][] = [];
          for (let i = dataStartIndex; i < lines.length; i++) {
            if (lines[i].startsWith('[COMPLETE]')) break;
            rows.push(lines[i].split(','));
          }

          requestParams.setRows(rows, totalRow);
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return error.message;
    });

  return response;
};

export default callExportLogDataApi;
