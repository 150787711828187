/* eslint-disable import/prefer-default-export */
import { isErrorType } from '../../types/apis/apiErrorTypeGuard';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
import type {
  DeviceSaveHistoryEnableApiRequestParam,
  DeviceSaveHistoryEnableAPIResponse,
  DeviceSaveHistoryEnableResponse,
} from '../../types/apis/deviceSaveHistoryEnableApi';
import callDeviceSaveHistoryLocalApi, {
  DEVICE_SAVE_HISTORY_LOCAL_API_ERROR_TYPE,
  DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE,
} from '../../apis/callDeviceSaveHistoryEnable';
// import { GroupAddResult } from '../../types/Group/groupAdd.d';

export const saveHistoryLocal = async (
  // accessKey: string,
  requestParams: DeviceSaveHistoryEnableApiRequestParam,
): Promise<DeviceSaveHistoryEnableAPIResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: DeviceSaveHistoryEnableResponse =
      await callDeviceSaveHistoryLocalApi(requestParams);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_CREATED':
        return {
          resultCode:
            DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.SUCCESSFULLY_CREATED,
        };
      // Failure
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.INFO_NOTHING_KEY;
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.INFO_INVALID:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.INFO_INVALID;
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.INFO_EXPIRED:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.INFO_EXPIRED;
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.WARN_INVALID_AUTH;
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.ERR_REQUEST:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.ERR_REQUEST;
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.DEVICE_IN_LOST_MODE:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.DEVICE_IN_LOST_MODE;
      case DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      default:
        throw DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: ALL_ERROR_TYPE =
      DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
    };
  }
};
