import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import { useSelector } from 'react-redux';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router';
import SimpleBarReact from 'simplebar-react';
import dayjs from 'dayjs';
import {
  TransCountInfo,
  TRANS_COUNT_INFO_KEY,
} from '../types/apis/transCountListApi.d';
import {
  convertTransCountInfoArrayToTableRowDataArray,
  sortedItems,
  updateSortConfig,
  filterArray,
} from '../utils/datatable/dataTableUtil';
import {
  createTotalPageNumber,
  // createDonwloadLinkAndClick,
} from '../utils/utility';
import {
  convertStrToDateyyyyMM01T00,
  fetchTodayUTCyyyyMM01T00,
} from '../utils/dateUtil';
import {
  TableHeaderData,
  TableRowData,
  SortConfig,
  TRANS_COUNT_TABLE_DATA_KEY,
  DIRECTION_TYPE,
  DisplayContentState,
  API_CALL_STATE,
  FILTERING_STATE,
} from '../types/datatable/dataTable.d';
import useFilter from './useFilter';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import type { FilterInput } from '../types/inputs/filterInput';
import usePagination from './usePagination';
import useDateRange, { UseDateRangeValue } from './useDateRange';
import useViewSwitchDropDown from './useViewSwitchDropDown';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import {
  DATE_FORMAT,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../constants/constants';
import {
  DownloadDate,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../types/download/downloadDate';
import {
  TRANS_COUNT_LIST_API_RESULT_CODE,
  TRANS_COUNT_LIST_ERROR_TYPE,
} from '../apis/callTransCountListApi';
import {
  formatDownloadDate,
  validateDownloadDate,
  createDonwloadErrorMessage,
} from '../utils/donwload/downloadDateUtil';
import { ViewType, VIEW_KEY } from '../types/viewswitch/viewSwitch.d';
import { ViewSwitchInput } from '../types/inputs/viewSwitchInput.d';
import { fetchTransCountList } from '../utils/report/transCountListUtil';
import {
  TransCountListResult,
  TRANS_COUNT_WIDTH_ARRAY,
} from '../types/report/transCountList.d';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import useSnackbar from './useSnackbar';
import { deviceDetailsStateProps } from './useDeviceList';
import { REPORT_VIEW_TYPE } from '../types/report/fromLangList.d';
import callReportDownload, { UrlList } from '../apis/callReportApi';
import { sendGAEvent } from './useGAEvent';
import { AppDispatch, RootState, useAppDispatch } from '../state/store';
import {
  DeviceListState,
  translationListSlice,
} from '../state/slice/translationCountSlice';
import { LOCALE_CODE } from './useLocale';
/**
 * ソート情報
 */
export type SortValue = {
  // ソート設定
  sortConfig: SortConfig;
  // テーブルの[ソート]ボタンがクリックされた場合の処理
  onClickSortButton: (data: TableHeaderData) => void;
};

/**
 * フィルタリング情報
 */
export type FilterValue = {
  // フィルタリングの[検索]ボタンがクリックされた
  onClickFilterSearch: (inputData: FilterInput) => void;
  // 入力中の検索ワード
  filterInputData: FilterInput;
};

/**
 * ページネーション情報
 */
export type PaginationValue = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // ページネーションの[前へ]ボタンがクリックされた場合の処理
  onClickPrevPage: () => void;
  // ページネーションの[次へ]ボタンがクリックされた場合の処理
  onClickNextPage: () => void;
  // 選択中の1ページごとの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された場合の処理
  onChangeRowNumber: (selectedRowNumber: number) => void;
  // ページ総数を変更
  changeTotalPages: (pages: number) => void;

  onClickLastPage: () => void;
  onClickFirstPage: () => void;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
};

/**
 * ビュー切替情報
 */
export type ViewSwitchValue = {
  // [ビュー切替]メニューの表示内容
  viewMenuContents: ViewType;
  // 現在選択中のビュー
  viewSwitchInputData: ViewSwitchInput;
  // [ビュー切替]ドロップダウンがクリックされた
  clickViewSwitch: (key: string) => void;
};

/**
 * 日付指定エラー情報
 */
export type DateRangeErrorValue = {
  // エラータイプ
  dateRangeAlertType: DOWNLOAD_DATE_ERROR_TYPE | null;
  // エラーメッセージ
  dateRangeErrorMessage: string;
};

/**
 * [翻訳履歴]ダイアログ情報
 */
export type TransHistoryDaialogValue = {
  // [翻訳履歴]ダイアログを表示するか否か
  isShowTransHistoryDaialog: boolean;
  // [翻訳履歴]ダイアログに渡すIMEI
  deviceDitailImei: MutableRefObject<string>;
  // [閉じる]ボタンまたはダイアログ外がクリックされた場合の処理
  onClickCloseModal: () => void;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseTransCountListValue = {
  // エラータイプ
  errorType: TRANS_COUNT_LIST_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // APIコール中か否か(true=コール中)
  isCallingApi: boolean;
  // アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
  onClickLogoutModal: () => void;
  // テーブルヘッダーに表示する内容
  headerColumns: TableHeaderData[];
  // 表示中のページに表示するテーブル内容
  currentPageDataRows: TableRowData[];
  // IMEIリンクがクリックされた場合の処理
  onClickImeiLink: (imei: string) => void;
  // 一覧総数
  totalListCount: number;
  // [一括ダウンロード]ボタンクリック
  clickDownload: () => void;
  // ソート情報
  sortValue: SortValue;
  // フィルタリング情報
  filterValue?: FilterValue;
  // ページネーション情報
  paginationValue: PaginationValue;
  // ビュー切替情報
  viewSwitchValue: ViewSwitchValue;
  // 日付範囲に関する値
  dateRangeValue: UseDateRangeValue;
  // ダウンロード日付選択に関する値
  downloadDate: DownloadDate;
  // 日付指定エラー情報
  dateRangeErrorValue: DateRangeErrorValue;
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // [翻訳履歴]ダイアログ情報
  transHistoryDaialogValue: TransHistoryDaialogValue;
  viewType: REPORT_VIEW_TYPE;
  isCallingReportApi: boolean;
  isFirstRenderingRef: React.MutableRefObject<boolean>;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
};

type Props = {
  searchTextInput: string;
  // locationKey: string;
};

/**
 * 翻訳回数画面 hooks
 *
 * @returns
 */
const useTransCountList = ({
  searchTextInput,
}: Props): UseTransCountListValue => {
  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState<number>(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);
  const [isCallingReportApi, setIsCallingReportApi] = useState<boolean>(false);

  const navigate = useNavigate();
  const deviceListStateInfo = useSelector<RootState, DeviceListState>(
    (state) => state.deviceListState,
  );
  const [viewType, setViewType] = useState<REPORT_VIEW_TYPE>(
    deviceListStateInfo.view ? deviceListStateInfo.view : 'd',
  );

  // 翻訳回数取得APIから取得した一覧 get/set
  const originalTransCountInfos = useRef<TransCountInfo[]>([]);
  // フィルタリング用一覧
  const filterTransCountInfos = useRef<TransCountInfo[]>([]);

  // テーブルに表示中のページデータ get/set
  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]);

  // 翻訳一覧取得エラータイプ get/set
  const [errorType, setErrorType] =
    useState<TRANS_COUNT_LIST_ERROR_TYPE | null>(null);
  // APIコール中か否か(true=コール中)
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // 一覧に表示中のデータ総数(翻訳件数の総数, フィルタリング結果数) get/set
  const [totalListCount, setTotalListCount] = useState<number>(0);
  // ソート状態 get/set
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: deviceListStateInfo.tableSortData?.key
      ? deviceListStateInfo.tableSortData.key
      : '',
    direction: deviceListStateInfo.tableSortData?.direction
      ? deviceListStateInfo.tableSortData.direction
      : DIRECTION_TYPE.none,
  });

  // 日付指定アラート状態
  const [dateRangeAlertType, setDateRangeAlertType] =
    useState<DOWNLOAD_DATE_ERROR_TYPE | null>(null);

  // アクセスキーhooks
  const { accessKey } = useAccessKey();
  // アカウント情報hooks
  const { accountInfo } = useAccountInfo();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();
  // [ビュー切替]ドロップダウン hooks
  const { viewMenuContents, viewSwitchInputData, clickViewSwitch } =
    useViewSwitchDropDown(deviceListStateInfo.view);
  // Date range HOOKS
  const { startDate, endDate, changeStartDate, changeEndDate } = useDateRange(
    deviceListStateInfo.fromDate
      ? new Date(deviceListStateInfo.fromDate)
      : dayjs().startOf('month').toDate(),
    deviceListStateInfo.toDate
      ? new Date(deviceListStateInfo.toDate)
      : new Date(),
  );
  // フィルターhooks
  // デフォルト値でフィルタリング復元情報を渡さない(ここで渡すと翻訳回数一覧取得前にフィルタリング復元が実行されて一覧結果が0件になるため)
  const { onClickFilterSearch, filterInputData } = useFilter('');
  // ページネーション hooks
  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    changeCurrentPage,
    onClickFirstPage,
    onClickLastPage,
  } = usePagination(
    deviceListStateInfo.tableRowNumber,
    deviceListStateInfo.pageNumber,
  );

  // 端末情報の更新状態管理hooks
  const { updateState, deviceDetailUpdateStateReset } =
    useDeviceDetailUpdateState();

  // 画面の表示状態を管理
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending, // API呼び出し状態(呼び出し前)
      filteringState: FILTERING_STATE.inactive, // フィルタリング適用状態(フィルタリング解除)
    });
  // 初回レンダリングか否か(true=初回レンダリング)
  const isFirstRenderingRef = useRef(true);

  // 選択できる最小値(法人登録日時)
  const minDate = useMemo(
    (): Date => convertStrToDateyyyyMM01T00(accountInfo.corpCreatedDt),
    [accountInfo.corpCreatedDt],
  );
  // 選択できる最大値(現在月)
  const maxDate = useMemo((): Date => fetchTodayUTCyyyyMM01T00(), []);

  // [翻訳履歴]ダイアログを表示するか否か(true=表示)
  const [isShowTransHistoryDaialog, setIsShowTransHistoryDaialog] =
    useState<boolean>(false);
  // [翻訳履歴]ダイアログに渡すIMEI
  const deviceDitailImei = useRef<string>('');

  // 翻訳回数取得APIから返却されたヘッダー（日、週、月ごとに変動）
  const [
    transCountApiResponseHeaderColums,
    setTransCountApiResponseHeaderColums,
  ] = useState<TableHeaderData[]>([]);
  /**
   * テーブルのヘッダーに表示する内容
   *
   * 言語切替、APIから返却されたヘッダーが更新された場合に表示用ヘッダー更新
   */
  const headerColumns = useMemo((): TableHeaderData[] => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // 固定ヘッダ
    const defaultColumns = [
      {
        key: TRANS_COUNT_TABLE_DATA_KEY.IMEI,
        value: t('transCountList.header.imei'),
        isSort: true,
        width: TRANS_COUNT_WIDTH_ARRAY[0],
      },
      {
        key: TRANS_COUNT_TABLE_DATA_KEY.ICCID,
        value: t('transCountList.header.iccid'),
        isSort: true,
        width: TRANS_COUNT_WIDTH_ARRAY[1],
      },
      {
        key: TRANS_COUNT_TABLE_DATA_KEY.DEVICE_NAME,
        value: t('transCountList.header.deviceName'),
        isSort: true,
        width: TRANS_COUNT_WIDTH_ARRAY[2],
      },
      {
        key: TRANS_COUNT_TABLE_DATA_KEY.GROUP_NAME,
        value: t('transCountList.header.groupName'),
        isSort: true,
        width: TRANS_COUNT_WIDTH_ARRAY[3],
      },
      {
        key: TRANS_COUNT_TABLE_DATA_KEY.PRODUCT_TYPE,
        value: t('transCountList.header.productType'),
        isSort: true,
        width: TRANS_COUNT_WIDTH_ARRAY[3],
      },
      {
        key: TRANS_COUNT_TABLE_DATA_KEY.TRANS_COUNT,
        value: t('transCountList.header.transCount'),
        isSort: true,
        width:
          // eslint-disable-next-line no-nested-ternary
          accountInfo.locale === LOCALE_CODE.FR
            ? isSafari
              ? '220px'
              : '190px'
            : accountInfo.locale === LOCALE_CODE.DE
            ? '240px'
            : TRANS_COUNT_WIDTH_ARRAY[4],
      },
    ];

    // 固定ヘッダとAPIから返却されたヘッダを統合
    return [...defaultColumns, ...transCountApiResponseHeaderColums];
  }, [accountInfo.locale, t, transCountApiResponseHeaderColums]);

  /**
   * 日付指定エラーメッセージ
   */
  const dateRangeErrorMessage = useMemo((): string => {
    if (!dateRangeAlertType) {
      return '';
    }

    return createDonwloadErrorMessage(
      dateRangeAlertType,
      t,
      DATE_FORMAT,
      minDate,
      maxDate,
    );
  }, [dateRangeAlertType, maxDate, minDate, t]);

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!errorType) {
      return ''; // エラーコンポーネント自体非表示にする
    }
    switch (errorType) {
      case TRANS_COUNT_LIST_API_RESULT_CODE.INFO_DATA_OVER:
        if (viewSwitchInputData.viewKey === VIEW_KEY.d) {
          return t('report.apiError.dateOver');
        }
        if (viewSwitchInputData.viewKey === VIEW_KEY.w) {
          return t('report.apiError.dateOverWeek');
        }

        return t('report.apiError.dateOverMonth');
      case TRANS_COUNT_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
      case TRANS_COUNT_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
      case TRANS_COUNT_LIST_API_RESULT_CODE.NO_INTERNET:
      case TRANS_COUNT_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
      case TRANS_COUNT_LIST_API_RESULT_CODE.USER_DEACTIVATED: 
        return '';
      default:
        return t('transCountList.transCountListApiError.unknown');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType, t]);

  const { displaySnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage) {
      displaySnackbar({ message: errorMessage, type: 'error' });
    }
    // eslint-disable-next-line
  }, [errorType]);

  const onClickImeiLink = useCallback((imei: string) => {
    const result = imei.split("_")[1];
    const imeiState: deviceDetailsStateProps = {
      imeiNo: result,
    };
    navigate(PAGE_PATH_NAME.DEVICE_DETAILS, { state: imeiState });
    deviceDitailImei.current = result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch: AppDispatch = useAppDispatch();

  const { addTableSortData, addFromDate, addToDate } =
    translationListSlice.actions;

  /**
   * [ソート]ボタンがクリックされた
   */
  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort) {
        // Do nothing if the sort is not allowed
        return;
      }

      // Sort information creation
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      setSortConfig(sortConfigUpdate);
      dispatch(addTableSortData(sortConfigUpdate));
    },
    // eslint-disable-next-line
    [sortConfig],
  );

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutModal = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setErrorType(null);
  };

  /**
   * 端末情報更新状態をリセット
   */
  const resetDeviceDetailUpdateState = (): void => {
    // 端末情報更新状態が初期値ではない場合はリセット
    if (
      updateState.deviceDetailUpdateState !== DEVICE_DETAIL_UPDATE_STATE.PENDING
    ) {
      deviceDetailUpdateStateReset();
    }
  };

  /**
   * [翻訳履歴]ダイアログの［閉じる］ボタンまたはダイアログ外がクリックされた場合の処理
   */
  const onClickCloseModal = (): void => {
    // ダイアログ閉じる
    setIsShowTransHistoryDaialog(false);

    // 端末情報が更新された場合のみ一覧更新
    if (
      updateState.deviceDetailUpdateState === DEVICE_DETAIL_UPDATE_STATE.SUCCESS
    ) {
      setDisplayContentState({
        ...displayContentState,
        apiCallState: API_CALL_STATE.pending,
      });
      isFirstRenderingRef.current = true;

      return;
    }
    // 端末更新状態をリセット
    resetDeviceDetailUpdateState();
  };

  /**
   * フィルタリング再実行
   */
  const performFilter = (): void => {
    // フィルタリング再実行(フィルタリング中にビュー切替が行われたときに実行されることを想定)
    if (searchTextInput !== '') {
      // 必要な内部処理が同じため、ボタンクリックイベントではないがここで呼び出す
      onClickFilterSearch({
        searchId: searchTextInput, // 一意のキーを指定すればいいので検索ワードを指定
        searchText: searchTextInput,
      });

      return;
    }
    // 端末情報更新状態をリセット
    resetDeviceDetailUpdateState();
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    allowTransition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [apiCalled, setApiCalled] = useState<number>(0);

  useEffect(() => {
    // 初回レンダリング時は何もしない(一覧取得が2回行われていたので制御追加)
    // if (isFirstRenderingRef.current) {
    //   isFirstRenderingRef.current = false;

    //   return;
    // }

    // API呼び出し前
    if (
      displayContentState.apiCallState === API_CALL_STATE.pending &&
      isFirstRenderingRef.current
    ) {
      isFirstRenderingRef.current = false;
      // loadingBar表示
      setIsCallingApi(true);
      // エラータイプ初期化
      setErrorType(null);
      // ここにきた時点でnullになることはありえないが、Date型を確定させるために処理入れる
      if (startDate == null || endDate == null) {
        return;
      }
      if (
        startDate.toDateString() === 'Invalid Date' ||
        endDate.toDateString() === 'Invalid Date'
      ) {
        return;
      }
      // 翻訳回数一覧取得
      const formattedStartDate = formatDownloadDate(startDate)?.slice(0, 6);
      const formattedEndDate = formatDownloadDate(endDate)?.slice(0, 6);
      dispatch(addFromDate(startDate));
      dispatch(addToDate(endDate));

      fetchTransCountList(
        // accessKey,
        accountInfo.corpId,
        formattedStartDate,
        formattedEndDate,
        viewSwitchInputData.viewKey,
      )
        .then((result: TransCountListResult) => {
          // alert(result.resultCode)
          if (
            result.resultCode ===
            TRANS_COUNT_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
          ) {
            if (result.transCountInfos != null) {
              // APIから返却された一覧をuseRefに追加
              originalTransCountInfos.current = result.transCountInfos;
              setViewType(result.viewType as REPORT_VIEW_TYPE);
              // APIから返却されたヘッダー
              setTransCountApiResponseHeaderColums([...result.tableHeader]);
              // state更新
              setDisplayContentState({
                ...displayContentState,
                apiCallState: API_CALL_STATE.fulfilled,
              });
            }

            return;
          }
          // エラー
          originalTransCountInfos.current = [];
  
          setErrorType(result.resultCode);
          // state更新
          setDisplayContentState({
            ...displayContentState,
            apiCallState: API_CALL_STATE.rejected,
          });
        })
        .finally(() => {
          // 一覧情報が揃ったタイミングでフィルタリング再実行
          performFilter();
          setApiCalled((api) => api + 1);
          // loadingBar非表示
          setIsCallingApi(false);
        });
    }
    // API呼び出し成功
    else if (displayContentState.apiCallState === API_CALL_STATE.fulfilled) {
      const firstPageIndex = (currentPage - 1) * currentRowNumberPerPage;
      const lastPageIndex = firstPageIndex + currentRowNumberPerPage;

      let list: TransCountInfo[] = [];

      // フィルターなし
      if (displayContentState.filteringState === FILTERING_STATE.inactive) {
        list = originalTransCountInfos.current;
      }
      // フィルターあり
      else if (displayContentState.filteringState === FILTERING_STATE.active) {
        list = filterTransCountInfos.current;
      }

      // 一覧をもとに表示用データに変換
      let pageDatas: TableRowData[] =
        convertTransCountInfoArrayToTableRowDataArray(list);

      // ソート適用
      if (sortConfig.direction !== DIRECTION_TYPE.none) {
        pageDatas = sortedItems(pageDatas, sortConfig);
      }
      // 1ページ分の表示内容に分割
      pageDatas = pageDatas.slice(firstPageIndex, lastPageIndex);

      // ページ総数
      const totalPageNumber = createTotalPageNumber(
        list.length,
        currentRowNumberPerPage,
      );

      // 現在ページがページ総数を超える場合は1ページ目に移動(ページ復元時に左記状態になる場合があるので対応)
      if (totalPageNumber < currentPage) {
        changeCurrentPage(1);
      }

      setCurrentPageDataRows(pageDatas); // 表示用データセット
      changeTotalPages(totalPageNumber); // ページ総数設定セット
      setTotalListCount(list.length); // 件数セット
      setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
      setPageDataIndexTo(
        currentPage * currentRowNumberPerPage > list.length
          ? list.length
          : currentPage * currentRowNumberPerPage,
      );
      // after pagination or sorting the scroll bar will be on top
      if (simpleBarRef.current) {
        simpleBarRef.current.getScrollElement().scrollTop = 0;
      }
    }
    // API呼び出し失敗
    else {
      // 表示用データセット
      setCurrentPageDataRows([]);
      // ページ総数設定セット
      changeTotalPages(1);
      // 件数セット
      setTotalListCount(0);
      // 1ページ目を表示
      changeCurrentPage(1);
      // 端末情報更新状態をリセット
      resetDeviceDetailUpdateState();
    }

    // startDate, endDate, changeCurrentPage, viewSwitchInputData.viewKeyは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessKey,
    accountInfo.corpId,
    changeTotalPages,
    currentPage,
    currentRowNumberPerPage,
    displayContentState,
    sortConfig,
  ]);

  useEffect(() => {
    if (
      startDate?.toDateString() === 'Invalid Date' ||
      endDate?.toDateString() === 'Invalid Date'
    ) {
      return;
    }
    setDisplayContentState({
      ...displayContentState,
      apiCallState: API_CALL_STATE.pending,
    });
    // eslint-disable-next-line
  }, [startDate, viewSwitchInputData.viewKey]);

  useEffect(() => {
    setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
    setPageDataIndexTo(
      currentPage * currentRowNumberPerPage > totalListCount
        ? totalListCount
        : currentPage * currentRowNumberPerPage,
    );
  }, [currentPage, currentRowNumberPerPage, totalListCount]);

  /**
   * フィルタリングが更新された場合の処理
   */
  useEffect(() => {
    // フィルタリング実行直後は1ページ目に移動
    // However, if the terminal name is updated in the [Translation History] dialog, it will not be moved to the first page to maintain the pagination.
    if (
      updateState.deviceDetailUpdateState !== DEVICE_DETAIL_UPDATE_STATE.SUCCESS
    ) {
      changeCurrentPage(deviceListStateInfo.pageNumber);
    }

    // 端末情報更新状態をリセット
    // [翻訳履歴]ダイアログから端末情報を更新して戻ってきた際にページ状態を復元させるためにこのタイミングでリセット
    resetDeviceDetailUpdateState();

    if (!searchTextInput) {
      // フィルターなし
      filterTransCountInfos.current = [];
      setDisplayContentState({
        ...displayContentState,
        filteringState: FILTERING_STATE.inactive,
      });

      return;
    }

    // 検索文字列
    const searchText: string = searchTextInput;
    // フィルター適用
    filterTransCountInfos.current = filterArray(
      originalTransCountInfos.current,
      searchText,
      [TRANS_COUNT_INFO_KEY.IS_DEREGISTER], // フィルタリング対象外のkey
    );
    setDisplayContentState({
      ...displayContentState,
      filteringState: FILTERING_STATE.active,
    });

    // displayContentStateは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextInput, apiCalled]);

  /**
   * 日付バリデーションチェック
   *
   * @returns true=OK / false=バリデーションエラー
   */
  const validateDate = useCallback((): boolean => {
    // 日付指定アラート状態初期化
    setDateRangeAlertType(null);
    // バリエーションチェック
    const result: DOWNLOAD_DATE_ERROR_TYPE | null = validateDownloadDate(
      startDate,
      endDate,
      minDate,
      maxDate,
      DATE_FORMAT,
    );
    if (result !== null) {
      // エラー結果を日付指定アラート状態に設定
      setDateRangeAlertType(result);

      return false;
    }

    return true;
  }, [startDate, endDate, minDate, maxDate]);

  /**
   * 期間開始日または期間終了日が変更された時点で、
   * 日付バリデーションチェック実行
   */
  useEffect(() => {
    validateDate();
  }, [validateDate]);

  /**
   * ビュー切替が変更された場合の処理
   */
  useEffect(() => {
    // API呼び出し前は何もしない
    if (displayContentState.apiCallState === API_CALL_STATE.pending) {
      return;
    }

    // 期間が変更された時点で日付バリエーションチェックが行われているが、
    // フォーカス外れるとエラーが表示されたままビュー切替可能なのでここでもチェックする
    if (!validateDate()) {
      return;
    }

    // After switching the view, move to the first page
    changeCurrentPage(1);
    // stateを更新して、一覧を再取得
    setDisplayContentState({
      ...displayContentState,
      apiCallState: API_CALL_STATE.pending,
    });

    // displayContentStateを検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewSwitchInputData]);

  /**
   * [一括ダウンロード]ボタンクリック
   *
   * @returns
   */
  const clickDownload = (): void => {
    setIsCallingReportApi(true);
    // すでに上でバリデーションチェックを行っているためnullになることはありえないが、Date型を確定させるために処理入れる
    if (startDate == null || endDate == null) {
      return;
    }
    if (
      startDate?.toDateString() === 'Invalid Date' ||
      endDate?.toDateString() === 'Invalid Date'
    ) {
      displaySnackbar({
        message: t('report.apiError.validRange'),
        type: 'error',
      });

      return;
    }
    const formattedStartDate = formatDownloadDate(startDate)?.slice(0, 6);
    const formattedEndDate = formatDownloadDate(endDate)?.slice(0, 6);
    callReportDownload(
      accountInfo.corpId,
      formattedStartDate,
      formattedEndDate,
      viewSwitchInputData.viewKey,
      UrlList.TransCount,
    )
      .then((response) => {
        if (response.message === SERVER_MESSAGE.FETCH_OK) {
          displaySnackbar({
            message: t('transCountList.transCountListApiError.success'),
          });
        } else {
          displaySnackbar({
            message: t('transCountList.transCountListApiError.failed'),
            type: 'error',
          });
        }
        setIsCallingReportApi(false);
      })
      .catch(() => {
        displaySnackbar({
          message: t('transCountList.transCountListApiError.failed'),
          type: 'error',
        });
        setIsCallingReportApi(false);
      });

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'exprt_data');
  };

  return {
    errorType,
    errorMessage,
    isCallingApi,
    onClickLogoutModal,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    viewType,
    onClickImeiLink,
    clickDownload,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    filterValue: {
      onClickFilterSearch,
      filterInputData,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onChangeRowNumber,
      changeTotalPages,
      onClickFirstPage,
      onClickLastPage,
      pageDataIndexFrom,
      pageDataIndexTo,
    },
    viewSwitchValue: {
      viewMenuContents,
      viewSwitchInputData,
      clickViewSwitch,
    },
    dateRangeValue: {
      startDate,
      endDate,
      changeStartDate,
      changeEndDate,
    },
    downloadDate: {
      minDate,
      maxDate,
    },
    dateRangeErrorValue: {
      dateRangeAlertType,
      dateRangeErrorMessage,
    },
    t,
    transHistoryDaialogValue: {
      isShowTransHistoryDaialog,
      deviceDitailImei,
      onClickCloseModal,
    },
    isCallingReportApi,
    isFirstRenderingRef,
    simpleBarRef,
  };
};

export default useTransCountList;
