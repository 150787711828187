import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { featureSetupInfo } from '../utils/remote/featureSetupInfo';
// import useAccessKey from './useAccessKey';
import type { featureSetupDataType } from '../types/remote/featureSetupData';
import {
  REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE,
  REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE,
} from '../apis/callRemoteFeatureSetupInfoApi';
import { isRemoteFeatureSetupInfoErrorType } from '../types/apis/apiErrorTypeGuard';
// import RedirectDialog from '../components/Modecules/RedirectDialog';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
// import useLogout from './useLogout';

type IDParams = {
  groupId: string;
};

const useRemoteFeatureSetupInfo = () => {
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();

  const [
    isCallingRemoteFeatureSetupInfoApi,
    setIsCallingRemoteFeatureSetupInfoApi,
  ] = useState<boolean>(false);
  const featureSetupData = useRef<featureSetupDataType | null>();
  const [featureSetupInfoErrorType, setFeatureSetupInfoErrorType] =
    useState<REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE | null>(null);
  // const { accessKey } = useAccessKey();
  const { groupId } = useParams<IDParams>();

  const onClickGroupList = () => {
    navigate(`/groups`, { replace: true });
  };

  const { t } = useSwitchLocaleLanguage();

  const onClickRemoteFeatureSetupInfo = (): void => {
    setIsCallingRemoteFeatureSetupInfoApi(true);
    featureSetupInfo(+groupId!)
      .then((result) => {
        // console.log(result.resultCode, 'resultCode');
        switch (result.resultCode) {
          case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.OK: {
            // Success Message
            featureSetupData.current = {
              groupPin: result.groupPin,
              sleepTime: result.sleepTime,
              // saveInLocalFlag: result.saveInLocalFlag,
              dataDeleteTime: result.dataDeleteTime,
              remoteSettingModeId: result.remoteSettingModeId,
              mobileDataEditable: result.mobileDataEditable,
              wifiEditable: result.wifiEditable,
              wifiSettings: result.wifiSettings,
              updateDt: result.updateDt,
            };
            break;
          }
          default:
            throw result.resultCode;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingRemoteFeatureSetupInfoApi(false);
      })
      .catch((error: REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE | unknown) => {
        if (isRemoteFeatureSetupInfoErrorType(error)) {
          setFeatureSetupInfoErrorType(error);
          if (
            error ===
            REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH
          ) {
            // displayRedirectDialog({
            //   open: true,
            //   title: t('common.error.notAuthorized'),
            //   message: t('common.error.roleChanged'),
            //   routePath: onClickLogoff,
            // });
          }
          if (
            error === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NO_GROUP
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup'),
            });
          }
          if (
            error ===
            REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.GROUP_ACCESS_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess'),
            });
          }
        } else {
          displaySnackbar({
            message: t('remoteDevice.message.setupFailed'),
            type: 'error',
          });
          setFeatureSetupInfoErrorType(
            REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingRemoteFeatureSetupInfoApi(false);
      });
  };

  const errorMessageRemoteFeatureSetupInfo = useMemo((): string => {
    if (!featureSetupInfoErrorType) {
      return '';
    }
    switch (featureSetupInfoErrorType) {
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NOTHING_KEY:
        return t('remoteDevice.message.noAccess');
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH:
        return t('common.error.unavailableGroup');
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        return t('remoteDevice.message.invalidURL');
      default:
        return t('common.error.serverErr');
    }
  }, [featureSetupInfoErrorType, t]);

  /*eslint-disable*/
  useEffect(() => {
    onClickRemoteFeatureSetupInfo();
  }, []);

  return {
    featureSetupData: featureSetupData.current,
    errorMessageRemoteFeatureSetupInfo,
    t,
    isCallingRemoteFeatureSetupInfoApi,
    featureSetupInfoErrorType,
    onClickRemoteFeatureSetupInfo,
    onClickGroupList,
  };
};

export default useRemoteFeatureSetupInfo;
