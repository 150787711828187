import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  LinearProgress,
  IconButton,
} from '@mui/material';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import { ReactComponent as CloseWhiteIcon } from '../../images/icon/cross-white.svg';
import { useStyles } from '../Templates/CommonStyle';

type ProgressDataProps = {
  percentage: number;
  groupCount: number;
  deviceCount: number;
};

type ProgressTextsProps = {
  heading: string;
  content: string;
  successMessage: string;
  failedMessage: string;
  time: string;
  closeButton: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  progressData: ProgressDataProps;
  total: number;
  progressTexts: ProgressTextsProps;
  progressErrorMessage: string;
  processStartTime: string;
  timeDifference: number;
  // isCallingApi: boolean;
  isCloseButtonDisable: boolean;
};

const ProgressDialog = React.memo<Props>((props): JSX.Element => {
  const {
    open,
    onClose,
    progressData,
    // eslint-disable-next-line
    total,
    progressTexts,
    progressErrorMessage,
    // processStartTime,
    // timeDifference,
    isCloseButtonDisable
  } = props;
  const classes = useStyles();
  const [isErrorCard, setIsErrorCard] = useState(false);

  useEffect(() => {
    if (progressErrorMessage) {
      setIsErrorCard(true);
    }
  }, [progressErrorMessage]);

  return (
    <Dialog open={open} className={classes.progressDialog}>
      <Box sx={{ width: '100%' }}>
        <DialogTitle className="res-dialog-title">
          <Box className="res-dialog-title-text"> {progressTexts.heading}</Box>
        </DialogTitle>
        <DialogContent
          className={`${classes.progressDialogContent} res-dialog-text`}
        >
          <Box sx={{ border: '1px solid #D9D9D9', padding: '6px' }}>
            {isErrorCard && (
              <Box className={classes.errorCardBulkAction}>
                <Box className={classes.errorCardBulkActionBox}>
                  <ErrorSign />
                  <Typography className={classes.errorCardBulkActionBoxText}>
                    {progressErrorMessage}
                  </Typography>
                </Box>

                <IconButton
                  sx={{ m: '0', height: '28px' }}
                  onClick={() => setIsErrorCard(false)}
                >
                  <CloseWhiteIcon />
                </IconButton>
              </Box>
            )}
            <Box sx={{ padding: '14px' }}>
              <Typography className={classes.progressDialogUpdatingText}>
                {progressTexts.content}
              </Typography>
              <Box style={{ position: 'relative', width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={progressData.percentage}
                  sx={{
                    height: '40px',
                    border: '1px solid #D9D9D9',
                    backgroundColor: '#F8F8F8',
                    '.MuiLinearProgress-bar': {
                      backgroundColor: progressErrorMessage
                        ? '#E1341E4D'
                        : '#009DE1',
                    },
                  }}
                />
                <span
                  style={{
                    position: 'absolute',
                    left: `50%`,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: progressData.percentage > 51 ? '#FFFFFF' : '#313133',
                    fontWeight: 500
                  }}
                >
                  {progressData.percentage ? Number(progressData.percentage.toFixed(0)): 0 }%
                </span>
              </Box>
              <Typography
                sx={{ fontSize: '14px', marginTop: '10px', lineHeight: '19px' }}
              >
                {progressTexts.time}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ marginTop: '20px !important' }}
          className={`${classes.dialogActionsCommon} res-dialog-btns`}
        >
          <Button
            className={classes.btnAction}
            onClick={() => {
              setIsErrorCard(false);
              onClose();
            }}
            disabled={isCloseButtonDisable}
            disableRipple
            sx={{ minWidth: '82px', padding: '10px 29.5px !important' }}
          >
            {progressTexts.closeButton}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
});

export default ProgressDialog;
