import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { PAGE_PATH_NAME, SERVER_MESSAGE } from '../constants/constants';
import type { DeviceDetailInput } from '../types/inputs/deviceDetailInput';
import type {
  DeviceInfoApiRequestParam,
  DeviceInfoApiResponse,
} from '../types/apis/deviceInfoApi';
// import useAccessKey from './useAccessKey';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import callDeviceInfoApi, {
  DEVICE_INFO_API_RESULT_CODE,
  DEVICE_INFO_ERROR_TYPE,
} from '../apis/callDeviceInfoApi';
import callDeviceUpdateApi, {
  DEVICE_UPDATE_API_RESULT_CODE,
  DEVICE_UPDATE_ERROR_TYPE,
} from '../apis/callDeviceUpdateApi';
import type {
  DeviceUpdateApiRequestParam,
  DeviceUpdateResponse,
} from '../types/apis/deviceUpdateApi';
import {
  isDeviceInfoErrorType,
  isDeviceUpdateErrorType,
} from '../types/apis/apiErrorTypeGuard';
import { convertISO8601StrToyyyyMMdd } from '../utils/dateUtil';
import type {
  FetchSimInfoApiRequestParameter,
  FetchSimInfoApiResponse,
} from '../types/apis/fetchSimInfoApi';
import callFetchSimInfoApi, {
  FETCH_SIM_INFO_API_RESULT_CODE,
} from '../apis/callFetchSimInfoApi';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import {
  DeviceUpdatePinApiRequestParam,
  DeviceUpdatePinApiResponse,
} from '../types/apis/deviceUpdatePinApi.d';
import callDeviceUpdatePinApi from '../apis/callUpdateDevicePinApi';
import useSnackbar from './useSnackbar';
import { sendGAEvent } from './useGAEvent';
import useAccountInfo from './useAccountInfo';

/**
 * 端末詳細に関する情報
 */
export type SimInfo = {
  deviceName: string;
  deviceModel: string;
  imei: string;
  iccid: string;
  isDeregister: boolean;
  latestTransdt: string;
  createdDt: string;
  expire: string;
  lostMode: boolean;
  softwareVersion: string;
  modeName: string;
  modeStatus: string;
  statusColor: string;
  featureLimitDt: string;
  lostModeDt: string;
  factoryResetDt: string;
  simExpDate: string;
  lastUsed: string;
  groupName: string;
  saveHistory: boolean;
};

/**
 * エラーに関する情報
 */
export type DeviceDetailError = {
  deviceInfoErrorType: DEVICE_INFO_ERROR_TYPE | null;
  deviceUpdateErrorType: DEVICE_UPDATE_ERROR_TYPE | null;
  errorMessage: string;
  onClickLogoutModal: () => void;
};

/**
 * 端末名編集に関する情報
 */
export type EditDeviceNameValue = {
  // [編集]ボタンクリック
  onClickEditButton: () => void;
  // [保存]ボタンクリック
  onClickEditSaveButton: (inputData: DeviceDetailInput) => void;
  // [キャンセル]ボタンクリック
  onClickEditCancelButton: () => void;
  // 編集状態(true=編集中)
  editState: boolean;
};

/**
 * 本カスタムフックの返り値
 */
export type UseDeviceDetailValue = {
  // 端末情報取得API、SIM情報取得APIをコール中か否か(true=コール中)
  isCallingApi: boolean;
  // 端末情報更新APIををコール中か否か(true=コール中)
  isCallingUpdateApi: boolean;
  // 画面上に表示する端末情報
  simInfo: SimInfo;
  // エラーに関する情報
  deviceDetailError: DeviceDetailError;
  // [端末登録解除]ボタンがクリックされた場合の処理
  onClickDeviceDeregister: () => void;
  // 端末名編集に関する情報
  editDeviceNameValue: EditDeviceNameValue;
  // 端末詳細画面の表示時に受け取ったIMEI
  targetImei: string;
  isCallingUpdatePinApi: boolean;
  pinUpdateResultCode: string;
  onClickUpdatePin: (updatePinValue: string) => void;
  onClickLostModeChange: (value: boolean) => void;
  factoryReset: boolean;
  setFactoryReset: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
};

/**
 * 端末詳細画面 hooks
 *
 * @param targetImei 詳細情報を表示したい端末のIMEI
 * @returns
 */
const useDeviceDetail = (targetImei: string): UseDeviceDetailValue => {
  const { accountInfo } = useAccountInfo();
  // アクセスキー
  // const { accessKey } = useAccessKey();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // 端末情報取得API、SIM情報取得APIをコール中かどうか
  const [isCallingApi, setIsCallingApi] = useState<boolean>(true); // 画面表示時にloadingBarを表示
  // Whether the terminal information update API is calling
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState<boolean>(false);
  // Device PIN Update API calling
  const [isCallingUpdatePinApi, setIsCallingUpdatePinApi] =
    useState<boolean>(false);
  // Device PIN update result Code
  const [pinUpdateResultCode, setPinUpdateResultCode] = useState<string>('');
  // 端末名
  const [deviceName, setDeviceName] = useState<string>('');
  // 機種名
  const [deviceModel, setDeviceModel] = useState<string>('');
  // IMEI
  const [imei, setImei] = useState<string>('');
  // ICCID
  const [iccid, setIccid] = useState<string>('');
  // 端末登録解除されているか(true=解除されている、false=解除されていない)
  const [isDeregister, setIsDeregister] = useState<boolean>(false);
  // 最終翻訳日
  const [latestTransdt, setLatestTransdt] = useState<string>('');
  // 端末登録日
  const [createdDt, setRegistdt] = useState<string>('');
  // 内蔵SIM通信期限
  const [expire, setExpire] = useState<string>('');
  const [lostMode, setLostMode] = useState<boolean>(false);
  const [factoryReset, setFactoryReset] = useState<boolean>(false);
  const [softwareVersion, setSoftwareVersion] = useState<string>('');
  const [modeName, setMode] = useState<string>('');
  const [statusColor, setStatusColor] = useState<string>('');
  const [modeStatus, setStatus] = useState<string>('');
  const [featureLimitDt, setFeatureLimitDt] = useState<string>('');
  const [lostModeDt, setLostModeDt] = useState<string>('');
  const [factoryResetDt, setFactoryResetDt] = useState<string>('');
  const [simExpDate, setSimExpDate] = useState<string>('');
  const [lastUsed, setLastUsed] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [saveHistory, setSaveHistory] = useState<boolean>(true);

  // 端末情報取得エラータイプ
  const [deviceInfoErrorType, setDeviceInfoErrorType] =
    useState<DEVICE_INFO_ERROR_TYPE | null>(null);

  // 端末情報更新エラータイプ
  const [deviceUpdateErrorType, setDeviceUpdateErrorType] =
    useState<DEVICE_UPDATE_ERROR_TYPE | null>(null);
  const { displaySnackbar } = useSnackbar();

  // URLパラメータの取得
  const location = useLocation();
  const [paginationState] = useState<{ transViewName: string }>(
    location.state as { transViewName: string },
  );

  // navigate(画面遷移)
  const navigate = useNavigate();

  // [編集]ボタンが押されたかどうか(編集状態かどうか)
  const [editState, setEditState] = useState<boolean>(false);
  // 端末情報の更新状態
  const { saveUpdateState } = useDeviceDetailUpdateState();

  /**
   * SIM情報取得APIのレスポンスから「内蔵SIM通信期限」を更新
   *
   * @param simInfoResponse
   */
  const updateSimExpiredt = (simInfoResponse: FetchSimInfoApiResponse) => {
    switch (simInfoResponse.resultCode) {
      case FETCH_SIM_INFO_API_RESULT_CODE.OK: {
        const { expiredt } = simInfoResponse.simInfo;
        if (expiredt) {
          setExpire(
            // SIM期限をyyyy/MM/ddに変換してセットする
            convertISO8601StrToyyyyMMdd(simInfoResponse.simInfo.expiredt),
          );
        } else {
          // expiredtが空の場合「無期限」をセット
          setExpire(t('deviceInfo.label.indefinite'));
        }
        break;
      }
      case FETCH_SIM_INFO_API_RESULT_CODE.INFO_NOT_FOUND_DB: {
        // 利用開始前
        setExpire(t('deviceInfo.label.beforeUse'));
        break;
      }
      case FETCH_SIM_INFO_API_RESULT_CODE.INFO_NOTHING_KEY:
      case FETCH_SIM_INFO_API_RESULT_CODE.INFO_INVALID:
      case FETCH_SIM_INFO_API_RESULT_CODE.INFO_EXPIRED:
        // APIからログイン認証エラーが返却された場合
        setDeviceInfoErrorType(DEVICE_INFO_API_RESULT_CODE.INFO_INVALID); // 一律アクセスキー不正エラーとする
        break;
      default:
        setExpire('');
    }
  };

  /**
   * 端末情報取得API呼び出し
   */
  const fetchDeviceInfo = (): void => {
    const deviceInfoApiRequestParam: DeviceInfoApiRequestParam = {
      imei: targetImei,
    };
    callDeviceInfoApi(deviceInfoApiRequestParam)
      .then(async (apiResponse: DeviceInfoApiResponse) => {
        if (apiResponse.message !== SERVER_MESSAGE.FETCH_OK) {
          // 端末情報の取得に失敗
          throw apiResponse.message;
        }
        // console.log(apiResponse, 'DeviceDetail');
        // OKの場合は端末情報をセット

        setDeviceName(apiResponse.details.deviceInfo.deviceName);
        setDeviceModel(apiResponse.details.deviceInfo.deviceModel);
        setImei(apiResponse.details.deviceInfo.imei);
        setIccid(apiResponse.details.deviceInfo.iccid);
        setIsDeregister(apiResponse.details.deviceInfo.isDeregister);
        setLostMode(apiResponse.details.deviceInfo.lostMode);
        setSoftwareVersion(apiResponse.details.deviceInfo.softwareVersion);
        setMode(apiResponse.details.deviceInfo.modeName);
        setStatus(apiResponse.details.deviceInfo.modeStatus);
        setStatusColor(apiResponse.details.deviceInfo.statusColor);
        setFeatureLimitDt(apiResponse.details.deviceInfo.featureLimitDt);
        setLostModeDt(apiResponse.details.deviceInfo.lostModeDt);
        setFactoryResetDt(apiResponse.details.deviceInfo.factoryResetDt);
        setLastUsed(apiResponse.details.deviceInfo.lastUsed);
        setSimExpDate(apiResponse.details.deviceInfo.simExpDate);
        setGroupName(apiResponse.details.deviceInfo.groupName);
        setSaveHistory(apiResponse.details.deviceInfo.saveInLocalFlag);
        if (apiResponse.details.deviceInfo.latestTransdt) {
          setLatestTransdt(
            convertISO8601StrToyyyyMMdd(
              apiResponse.details.deviceInfo.latestTransdt,
            ),
          );
        }

        if (apiResponse.details.deviceInfo.createdDt) {
          setRegistdt(
            convertISO8601StrToyyyyMMdd(
              apiResponse.details.deviceInfo.createdDt,
            ),
          );
        }

        // 端末情報取得APIのレスポンスのiccidが空でない場合SIM情報取得API呼び出し
        if (apiResponse.details.deviceInfo.iccid) {
          // SIM情報取得API呼び出し
          const fetchSimInfoApiRequestParam: FetchSimInfoApiRequestParameter = {
            imei: targetImei,
          };

          const simInfoResponse: FetchSimInfoApiResponse =
            await callFetchSimInfoApi(fetchSimInfoApiRequestParam);

          // SIM期限の更新
          updateSimExpiredt(simInfoResponse);
        } else {
          setExpire(t('deviceInfo.label.beforeUse'));
        }
      })
      .catch((error: DEVICE_INFO_ERROR_TYPE | unknown) => {
        // 端末情報取得エラーorログイン認証エラー
        if (isDeviceInfoErrorType(error)) {
          setDeviceInfoErrorType(error);
          if (error === SERVER_MESSAGE.DEVICE_NOT_FOUND) {
            displaySnackbar({
              message: t('deviceInfo.deviceNotFoundApiError'),
              type: 'error',
            });
            navigate(-1);
          }
        } else {
          setDeviceInfoErrorType(DEVICE_INFO_API_RESULT_CODE.ERR_UNKNOWN);
          displaySnackbar({
            message: t('deviceInfo.deviceInfoApiError'),
            type: 'error',
          });
        }
      })
      .finally(() => {
        // loadingBar非表示
        setIsCallingApi(false);
      });
  };

  /**
   * 各種エラータイプを初期化
   */
  const resetErrorType = (): void => {
    setDeviceInfoErrorType(null);
    setDeviceUpdateErrorType(null);
  };

  const onClickLostModeChange = (value: boolean) => {
    setLostMode(value);
  };

  useEffect(() => {
    // 各種エラータイプ初期化
    resetErrorType();

    // 端末情報取得API呼び出し
    fetchDeviceInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostMode, factoryReset, accountInfo.locale]);

  /**
   * [保存]ボタン押下時処理
   * 保存に成功で編集状態を解除(失敗した場合は解除しない)
   *
   * @param inputData
   */
  const onClickEditSaveButton = (inputData: DeviceDetailInput) => {
    // 端末情報更新API呼び出し中(保存ボタン、キャンセルボタン、端末名テキストボックスをDisabledで表示)
    setIsCallingUpdateApi(true);

    // 各種エラータイプ初期化
    resetErrorType();

    // 端末情報変更処理
    const deviceUpdateApiRequestParam: DeviceUpdateApiRequestParam = {
      imei,
      deviceName: inputData.deviceName?.trim(),
    };

    void callDeviceUpdateApi(deviceUpdateApiRequestParam)
      .then((apiResponse: DeviceUpdateResponse) => {
        if (apiResponse.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED) {
          displaySnackbar({
            message: t('deviceInfo.devicePermissionApiError'),
            type: 'error',
          });
          setIsCallingUpdateApi(false);
          saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.REJECTED);
          navigate(PAGE_PATH_NAME.DEVICE_LIST);

          return;
        }
        if (apiResponse.message !== SERVER_MESSAGE.UPDATE_OK) {
          if (
            apiResponse.message !== SERVER_MESSAGE.USER_ROLE_CHANGED &&
            apiResponse.message !== SERVER_MESSAGE.INVALID_TOKEN &&
            apiResponse.message !== SERVER_MESSAGE.EXPIRED_TOKEN
          ) {
            displaySnackbar({
              message: t('deviceInfo.deviceUpdateApiError'),
              type: 'error',
            });
          }
          throw apiResponse.message;
        }
        // Since the screen side becomes an old terminal name, update the screen display here.
        setDeviceName(inputData.deviceName?.trim());
        // 保存に成功したので編集状態を解除
        setEditState(false);
        // 端末情報更新API呼び出し完了(保存ボタン、キャンセルボタン、端末名テキストボックスをEnabledで表示)
        // finallyで実行すると警告が出るためここで実行する)
        setIsCallingUpdateApi(false);
        // 更新状態保存
        saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.SUCCESS);
        displaySnackbar({
          message: t('deviceInfo.message.updateSuccess'),
        });
      })
      .catch((error: DEVICE_UPDATE_ERROR_TYPE | unknown) => {
        // 端末情報更新エラーorログイン認証エラー
        if (isDeviceUpdateErrorType(error)) {
          setDeviceUpdateErrorType(error);
        } else {
          setDeviceUpdateErrorType(DEVICE_UPDATE_API_RESULT_CODE.ERR_UNKNOWN);
          displaySnackbar({
            message: t('deviceInfo.deviceUpdateApiError'),
            type: 'error',
          });
        }

        // 端末情報更新API呼び出し完了(保存ボタン、キャンセルボタン、端末名テキストボックスをEnabledで表示)
        setIsCallingUpdateApi(false);
        // 更新状態保存
        saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.REJECTED);
      });

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'save_device_name');
    sendGAEvent(
      'press_btn',
      'save_device_name_value	',
      inputData.deviceName?.trim(),
    );
  };

  /**
   * Device PIN Update API Call
   */
  const onClickUpdatePin = (updatePinValue: string) => {
    setIsCallingUpdatePinApi(true);

    const deviceUpdatePinApiRequestParam: DeviceUpdatePinApiRequestParam = {
      imei,
      pin: updatePinValue,
      lockStatus: true,
    };

    void callDeviceUpdatePinApi(deviceUpdatePinApiRequestParam)
      .then((apiResponse: DeviceUpdatePinApiResponse) => {
        if (apiResponse.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED) {
          displaySnackbar({
            message: t('deviceInfo.devicePermissionApiError'),
            type: 'error',
          });
          setPinUpdateResultCode(apiResponse.message);
          setIsCallingUpdatePinApi(false);
          navigate(PAGE_PATH_NAME.DEVICE_LIST);

          return;
        }
        if (
          apiResponse.message === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
          apiResponse.message === SERVER_MESSAGE.USER_ROLE_CHANGED ||
          apiResponse.message === SERVER_MESSAGE.INVALID_TOKEN ||
          apiResponse.message === SERVER_MESSAGE.EXPIRED_TOKEN ||
          apiResponse.message === SERVER_MESSAGE.NO_INTERNET
        ) {
          setIsCallingUpdatePinApi(false);

          return;
        }
        if (apiResponse.message !== SERVER_MESSAGE.UPDATE_OK) {
          displaySnackbar({
            message: t('lostMode.message.updatePinFailed'),
            type: 'error',
            timeout: 3100,
          });
          setPinUpdateResultCode(apiResponse.message);
          setIsCallingUpdatePinApi(false);

          return;
        }
        if (apiResponse.message === SERVER_MESSAGE.UPDATE_OK) {
          fetchDeviceInfo();
          displaySnackbar({ message: t('lostMode.message.pinSuccess') });
          setIsCallingUpdatePinApi(false);
          setPinUpdateResultCode(apiResponse.message);

          return;
        }

        displaySnackbar({
          message: t('lostMode.message.updatePinFailed'),
          type: 'error',
          timeout: 3100,
        });
        setIsCallingUpdatePinApi(false);
        setPinUpdateResultCode(apiResponse.message);
        // throw apiResponse.resultCode;
      })
      .catch((error) => {
        if (error) {
          displaySnackbar({
            message: t('lostMode.message.updatePinFailed'),
            type: 'error',
            timeout: 3100,
          });
          setIsCallingUpdatePinApi(false);
        }
      })
      .finally(() => {
        // Loading End
        setIsCallingUpdatePinApi(false);
      });
  };
  /**
   * [編集]ボタン押下
   */
  const onClickEditButton = () => {
    // 編集状態へ更新
    setEditState(true);
  };

  /**
   * [キャンセル]ボタン押下
   */
  const onClickEditCancelButton = () => {
    // 編集状態を解除
    setEditState(false);
  };

  /**
   * [端末登録解除]ボタン押下処理
   */
  const onClickDeviceDeregister = () => {
    navigate(PAGE_PATH_NAME.DEVICE_DEREGISTER, {
      state: {
        imei,
        transViewName: paginationState.transViewName,
      },
    });
  };

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!deviceInfoErrorType && !deviceUpdateErrorType) {
      return ''; // // エラーコンポーネント自体非表示にする
    }

    // 端末情報取得エラー
    if (deviceInfoErrorType) {
      switch (deviceInfoErrorType) {
        case DEVICE_INFO_API_RESULT_CODE.INFO_NOTHING_KEY:
        case DEVICE_INFO_API_RESULT_CODE.INFO_INVALID:
        case DEVICE_INFO_API_RESULT_CODE.INFO_EXPIRED:
          return ''; // ログイン認証はエラーはエラーダイアログを出すのでメッセージなし
        default:
          return t('deviceInfo.deviceInfoApiError');
      }
    }
    // 端末情報更新エラー
    if (deviceUpdateErrorType) {
      switch (deviceUpdateErrorType) {
        case DEVICE_UPDATE_API_RESULT_CODE.INFO_NOTHING_KEY:
        case DEVICE_UPDATE_API_RESULT_CODE.INFO_INVALID:
        case DEVICE_UPDATE_API_RESULT_CODE.INFO_EXPIRED:
        case DEVICE_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH:
          return ''; // ログイン認証、権限不正エラーはエラーダイアログを出すのでメッセージなし
        default:
          return t('deviceInfo.deviceUpdateApiError');
      }
    }

    return t('deviceInfo.deviceInfoApiError');
  }, [deviceInfoErrorType, deviceUpdateErrorType, t]);

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutModal = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setDeviceInfoErrorType(null);
    setDeviceUpdateErrorType(null);
  };

  return {
    isCallingApi,
    isCallingUpdateApi,
    t,
    isCallingUpdatePinApi,
    onClickUpdatePin,
    pinUpdateResultCode,
    simInfo: {
      deviceName,
      deviceModel,
      imei,
      iccid,
      isDeregister,
      latestTransdt,
      createdDt,
      expire,
      lostMode,
      softwareVersion,
      modeName,
      modeStatus,
      statusColor,
      featureLimitDt,
      lostModeDt,
      factoryResetDt,
      lastUsed,
      groupName,
      simExpDate,
      saveHistory,
    },
    deviceDetailError: {
      deviceInfoErrorType,
      deviceUpdateErrorType,
      errorMessage,
      onClickLogoutModal,
    },
    onClickDeviceDeregister,
    editDeviceNameValue: {
      onClickEditButton,
      onClickEditSaveButton,
      onClickEditCancelButton,
      editState,
    },
    targetImei,
    onClickLostModeChange,
    factoryReset,
    setFactoryReset,
  };
};
export default useDeviceDetail;
