import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import callDashboardDeviceInfoApi from '../apis/callDashboardDeviceInfoApi';
import callDashboardRemoteSettingApi from '../apis/callDashboardRemoteSettingApi';

import useSnackbar from './useSnackbar';
import { DeviceInfo } from '../types/apis/dashboardDeviceInfo.d';
import { RemoteSetting } from '../types/apis/dashboardRemoteSetting.d';
import useAccountInfo from './useAccountInfo';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import {
  fetchAverageTranslations,
  fetchLanguageFromTranslation,
  fetchLanguageToTranslation,
  fetchNumberOfTranslations,
  fetchTotalTranslations,
} from '../utils/dashboard/dashboardUtil';
import { dateRangeCalculator } from '../utils/dashboard/dateRangeCalculator';
import { TOTAL_TRANSLATIONS_ERROR_TYPE } from '../apis/callTotalTranslationsApi';
import { NumberOfTranslations } from '../types/apis/numberOfTranslationsApi.d';
import { LanguageFromDataType } from '../types/apis/languageFromTranslationApi.d';
import useAccessKey from './useAccessKey';
import { decryptText } from '../utils/utility';
import callApiUtil from '../utils/callApiUtil';

export type DashboardProps = {
  isCallingDeviceApi: boolean;
  isCallingRemoteApi: boolean;

  onClickDeviceApi: () => void;
  onClickRemoteSettingApi: () => void;
  deviceValue: DeviceInfo;
  remoteSettingValue: RemoteSetting;
  callTotalTranslationApi: (dateRange: {
    firstDay: string;
    lastDay: string;
  }) => void;
  totalTranslations: string;
  callAverageTranslationApi: (dateRange: {
    firstDay: string;
    lastDay: string;
  }) => void;
  averageTranslations: string;
  isCallingTotalTranslationsApi: boolean;
  isCallingAverageTranslationsApi: boolean;
  callNumberOfTranslationsApi: (
    dateRange: {
      firstDay: string;
      lastDay: string;
    },
    yearFlag: boolean,
  ) => void;
  isCallingNumberOfTranslationsApi: boolean;
  numberOfTranslationsData: NumberOfTranslations[];
  isCallingLanguageFromTranslationApi: boolean;
  callLanguageFromTranslationApi: (dateRange: {
    firstDay: string;
    lastDay: string;
  }) => void;
  languageFromTranslationData: LanguageFromDataType[];
  isCallingLanguageToTranslationApi: boolean;
  callLanguageToTranslationApi: (dateRange: {
    firstDay: string;
    lastDay: string;
  }) => void;
  languageToTranslationData: LanguageFromDataType[];
};

const useDashboard = (): DashboardProps => {
  const [isCallingDeviceApi, setIsCallingDeviceApi] = useState<boolean>(true);
  const [isCallingRemoteApi, setIsCallingRemoteApi] = useState<boolean>(false);
  const [isCallingTotalTranslationsApi, setIsCallingTotalTranslationsApi] =
    useState<boolean>(false);
  const [isCallingAverageTranslationsApi, setIsCallingAverageTranslationsApi] =
    useState<boolean>(false);

  const [deviceValue, setDeviceValue] = useState<DeviceInfo>({
    totalDeviceCount: 0,
    totalLostModeDeviceCount: 0,
    totalSimExpiredDeviceCount: 0,
  });
  const [remoteSettingValue, setRemoteSettingValue] = useState<RemoteSetting>({
    dataProtection: '',
    featureLimit: '',
    wifiSettings: '',
    saveHistorySettings: '',
  });
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();
  const { accessKeyRedux } = useAccessKey();
  const { accountInfo } = useAccountInfo();
  const location = useLocation();

  // update token for new user in case of multiple login from same browser
  callApiUtil.defaults.headers.common[
    'Console-Authorization'
  ] = `Console-Token ${decryptText(accessKeyRedux)}`;

  const onClickDeviceApi = () => {
    setIsCallingDeviceApi(true);
    void callDashboardDeviceInfoApi()
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.FETCH_OK: {
            setDeviceValue(apiResponse.details);
            setIsCallingDeviceApi(false);

            break;
          }
          case SERVER_MESSAGE.NO_INTERNET: {
            displaySnackbar({
              message: t('common.error.noInternet'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          case SERVER_MESSAGE.USER_CONSENT_REQUIRED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_AUTH0_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.USER_DEACTIVATED: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default: {
            displaySnackbar({
              message: t('common.error.serverErr'),
              type: 'error',
            });
            throw apiResponse.message;
          }
        }
        setIsCallingDeviceApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'error');
        setIsCallingDeviceApi(false);
      });
  };

  const onClickRemoteSettingApi = () => {
    setIsCallingRemoteApi(true);
    void callDashboardRemoteSettingApi()
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.FETCH_OK: {
            setRemoteSettingValue(apiResponse.details);
            setIsCallingRemoteApi(false);

            break;
          }
          case SERVER_MESSAGE.NO_INTERNET: {
            displaySnackbar({
              message: t('common.error.noInternet'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          case SERVER_MESSAGE.USER_CONSENT_REQUIRED: {
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_AUTH0_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.USER_DEACTIVATED: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default: {
            displaySnackbar({
              message: t('common.error.serverErr'),
              type: 'error',
            });
            throw apiResponse.message;
          }
        }
        setIsCallingDeviceApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'error');
        setIsCallingRemoteApi(false);
      });
  };

  useEffect(() => {
    if (accountInfo.latestEulaConsented) {
      onClickDeviceApi();
      onClickRemoteSettingApi();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.latestEulaConsented, accountInfo.locale, location.key]);

  const [totalTranslations, setTotalTranslations] = useState<string>('0');
  const [averageTranslations, setAverageTranslations] = useState<string>('0');

  const callTotalTranslationApi = useCallback(
    (dateRange: { firstDay: string; lastDay: string }) => {
      setIsCallingTotalTranslationsApi(true);
      fetchTotalTranslations(dateRange)
        .then((result) => {
          setTotalTranslations(result.details.totalTranslations);
        })
        .catch((error: TOTAL_TRANSLATIONS_ERROR_TYPE | unknown) => {
          console.log(error);
        })
        .finally(() => {
          setIsCallingTotalTranslationsApi(false);
        });
      // setting previous api payload
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountInfo.latestEulaConsented],
  );

  const callAverageTranslationApi = useCallback(
    (dateRange: { firstDay: string; lastDay: string }) => {
      setIsCallingAverageTranslationsApi(true);
      fetchAverageTranslations(dateRange)
        .then((result) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          setAverageTranslations(result.details.averageTranslation);
        })
        .catch((error: TOTAL_TRANSLATIONS_ERROR_TYPE | unknown) => {
          console.log(error);
        })
        .finally(() => {
          setIsCallingAverageTranslationsApi(false);
        });
      // setting previous api payload
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountInfo.latestEulaConsented],
  );

  // area chart operations (Number of Translations)
  const [
    isCallingNumberOfTranslationsApi,
    setIsCallingNumberOfTranslationsApi,
  ] = useState<boolean>(false);

  const [numberOfTranslationsData, setNumberOfTranslationsData] = useState<
    NumberOfTranslations[]
  >([]);

  const callNumberOfTranslationsApi = useCallback(
    (dateRange: { firstDay: string; lastDay: string }, yearFlag: boolean) => {
      setIsCallingNumberOfTranslationsApi(true);
      fetchNumberOfTranslations(dateRange, yearFlag)
        .then((result) => {
          setNumberOfTranslationsData(result.details.noOfTranslations);
        })
        .catch((error: TOTAL_TRANSLATIONS_ERROR_TYPE | unknown) => {
          console.log(error);
        })
        .finally(() => {
          setIsCallingNumberOfTranslationsApi(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountInfo.latestEulaConsented],
  );

  useEffect(() => {
    const dateRange = dateRangeCalculator('This Month');
    callNumberOfTranslationsApi(dateRange, false);
  }, [callNumberOfTranslationsApi]);

  // --------------doughnut chart operations (Languages Used for Translation From)
  const [
    isCallingLanguageFromTranslationApi,
    setIsCallingLanguageFromTranslationApi,
  ] = useState<boolean>(false);

  const [languageFromTranslationData, setLanguageFromTranslationData] =
    useState<LanguageFromDataType[]>([]);
  const callLanguageFromTranslationApi = useCallback(
    (dateRange: { firstDay: string; lastDay: string }) => {
      setIsCallingLanguageFromTranslationApi(true);
      fetchLanguageFromTranslation(dateRange)
        .then((result) => {
          setLanguageFromTranslationData(result.details.translationCounts);
        })
        .catch((error: TOTAL_TRANSLATIONS_ERROR_TYPE | unknown) => {
          console.log(error);
        })
        .finally(() => {
          setIsCallingLanguageFromTranslationApi(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountInfo.latestEulaConsented],
  );

  // -------------- doughnut chart operations (Languages Used for Translation To)
  const [
    isCallingLanguageToTranslationApi,
    setIsCallingLanguageToTranslationApi,
  ] = useState<boolean>(false);

  const [languageToTranslationData, setLanguageToTranslationData] = useState<
    LanguageFromDataType[]
  >([]);
  const callLanguageToTranslationApi = useCallback(
    (dateRange: { firstDay: string; lastDay: string }) => {
      setIsCallingLanguageToTranslationApi(true);
      fetchLanguageToTranslation(dateRange)
        .then((result) => {
          setLanguageToTranslationData(result.details.translationCounts);
        })
        .catch((error: TOTAL_TRANSLATIONS_ERROR_TYPE | unknown) => {
          console.log(error);
        })
        .finally(() => {
          setIsCallingLanguageToTranslationApi(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountInfo.latestEulaConsented],
  );

  return {
    isCallingDeviceApi,
    onClickDeviceApi,
    deviceValue,
    isCallingRemoteApi,
    onClickRemoteSettingApi,
    remoteSettingValue,
    callTotalTranslationApi,
    totalTranslations,
    callAverageTranslationApi,
    averageTranslations,
    isCallingTotalTranslationsApi,
    isCallingAverageTranslationsApi,
    callNumberOfTranslationsApi,
    isCallingNumberOfTranslationsApi,
    numberOfTranslationsData,
    isCallingLanguageFromTranslationApi,
    callLanguageFromTranslationApi,
    languageFromTranslationData,
    languageToTranslationData,
    isCallingLanguageToTranslationApi,
    callLanguageToTranslationApi,
  };
};
export default useDashboard;
