/* eslint-disable */
export enum sSeriseDeviceEnum {
    PocketalkW = 'Pocketalk W',
    PocketalkS = 'Pocketalk S',
    PocketalkSPlus = 'Pocketalk S Plus'
  }

  export enum s2SeriseDeviceEnum {
    PocketalkS2 = 'Pocketalk S2',
    PocketalkS2Plus = 'Pocketalk S2 Plus',
  }