import { API_URL } from '../constants/constants';
import type {
  DeviceSaveHistoryEnableApiRequestParam,
  DeviceSaveHistoryEnableResponse,
} from '../types/apis/deviceSaveHistoryEnableApi';
import callApiUtil from '../utils/callApiUtil';

export const DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE = {
  SUCCESSFULLY_CREATED: 'SUCCESSFULLY_CREATED',
  DEVICE_IN_LOST_MODE: 'DEVICE_IN_LOST_MODE',
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
} as const;

export type DEVICE_SAVE_HISTORY_LOCAL_API_ERROR_TYPE =
  (typeof DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE)[keyof typeof DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE];

const callDeviceSaveHistoryLocalApi = (
  requestParams: DeviceSaveHistoryEnableApiRequestParam,
): Promise<DeviceSaveHistoryEnableResponse> => {
  /*eslint-disable*/
  const jsonParams = {
    imei: requestParams.imei,
    saveInLocalFlag: requestParams.saveInLocalFlag,
  };

  const response = callApiUtil
    .post<DeviceSaveHistoryEnableResponse>(
      API_URL.DEVICE_SAVE_HISTORT_LOCAL,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callDeviceSaveHistoryLocalApi;
